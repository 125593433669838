import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StatisticsWrapper } from './styles';
import { StatisticTable } from './components';

const SubscriptionsStatistics: FC = () => {
  const { t } = useTranslation();
  return (
    <StatisticsWrapper>
      <div>
        <h1>{t('subscriptions_statistics.title')}</h1>
      </div>
      <StatisticTable />
    </StatisticsWrapper>
  );
};

export default SubscriptionsStatistics;
