import React, { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TimePickerStyled, Wrapper } from './styles';
import { TimePickerProps } from './modules';
import { renderMultiSectionDigitalClockTimeView } from '@mui/x-date-pickers';

const TimePicker: FC<TimePickerProps> = ({
  defaultValue,
  value,
  onChange,
  minTime,
  maxTime,
  disabled,
  isError,
  disableIgnoringDatePartForTimeValidation = true,
  timeStepsMinutes,
  onOpen,
}) => {
  const handleChange = (value: Dayjs) => {
    const time = value ? dayjs(value).toDate() : '';
    onChange(time as any);
  };

  return (
    <Wrapper isError={isError}>
      <TimePickerStyled
        ampm={false}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        minTime={minTime}
        maxTime={maxTime}
        onChange={(value) => handleChange(value as Dayjs)}
        disableIgnoringDatePartForTimeValidation={
          disableIgnoringDatePartForTimeValidation
        }
        closeOnSelect={false}
        timeSteps={{ minutes: timeStepsMinutes || 5 }}
        onOpen={onOpen}
        viewRenderers={{
          hours: renderMultiSectionDigitalClockTimeView,
          minutes: renderMultiSectionDigitalClockTimeView,
          seconds: renderMultiSectionDigitalClockTimeView,
        }}
        slotProps={{ toolbar: { hidden: true } }}
      />
    </Wrapper>
  );
};

export default TimePicker;
