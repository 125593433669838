import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AdminsActions,
  AppointmentsDocumentsActions,
  TeleconsultationActions,
  ClinicOwnerActions,
  PatientsActions,
  PhotoActions,
  ProfessionalsActions,
  SecretariesActions,
  UsersActions,
} from 'applicaiton/constants/actions';
import { getToken } from 'applicaiton/sessionStorage/auth';

export const initialState = {
  error: '',
  isErrorModalVisible: false,
};

const excludeTypes = (action: PayloadAction<any>) => {
  switch (action.type) {
    case `${ClinicOwnerActions.signUpConfirmOwner}/rejected`:
      return false;
    case `${UsersActions.usersLogin}/rejected`:
      return false;
    case `${UsersActions.userChangePassword}/rejected`:
      return false;
    case `${PatientsActions.updatePatients}/rejected`:
      return !(
        action.payload.statusCode === 403 || action.payload.statusCode === 500
      );
    case `${ClinicOwnerActions.signUpOwner}/rejected`:
    case `${SecretariesActions.secretaryVerifyCode}/rejected`:
    case `${SecretariesActions.secretarySetNewPhone}/rejected`:
    case `${SecretariesActions.secretarySetNewEmail}/rejected`:
    case `${AdminsActions.adminVerifyCode}/rejected`:
    case `${AdminsActions.adminSetNewPhone}/rejected`:
    case `${AdminsActions.adminSetNewEmail}/rejected`:
    case `${ClinicOwnerActions.clinicOwnerVerifyCode}/rejected`:
    case `${ClinicOwnerActions.clinicOwnerSetNewPhone}/rejected`:
    case `${ClinicOwnerActions.clinicOwnerSetNewEmail}/rejected`:
    case `${ProfessionalsActions.professionalVerifyCode}/rejected`:
    case `${ProfessionalsActions.professionalSetNewEmail}/rejected`:
    case `${UsersActions.usersVerificationCode}/rejected`:
    case `${ProfessionalsActions.updateProfessionalEmail}/rejected`:
    case `${ProfessionalsActions.createProfessionalPassword}/rejected`:
    case `${AdminsActions.createAdminPassword}/rejected`:
    case `${SecretariesActions.createSecretariesPassword}/rejected`:
    case `${SecretariesActions.checkSecretariesVerificationCode}/rejected`:
    case `${AdminsActions.checkAdminVerificationCode}/rejected`:
    case `${ProfessionalsActions.checkProfessionalVerificationCode}/rejected`:
    case `${PhotoActions.uploadPhoto}/rejected`:
    case `${UsersActions.usersRefreshToken}/rejected`:
    case `${AdminsActions.adminVerifyOldEmail}/rejected`:
    case `${SecretariesActions.secretaryVerifyOldEmail}/rejected`:
    case `${ClinicOwnerActions.clinicOwnerVerifyOldEmail}/rejected`:
    case `${ProfessionalsActions.professionalVerifyOldEmail}/rejected`:
    case `${AppointmentsDocumentsActions.postDocuments}/rejected`:
    case `${AdminsActions.adminVerifyPhone}/rejected`:
    case `${ClinicOwnerActions.clinicOwnerVerifyPhone}/rejected`:
    case `${ProfessionalsActions.professionalVerifyPhone}/rejected`:
    case `${SecretariesActions.secretaryVerifyPhone}/rejected`:
    case `${ProfessionalsActions.professionalSetNewPhone}/rejected`:
    case `${PatientsActions.uploadCSV}/rejected`:
    case `patients/${PatientsActions.getPatientById}/rejected`:
    case `${TeleconsultationActions.getConnectionDataByRoomId}/rejected`:
      return false;
    default:
      return true;
  }
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setIsErrorModalVisible: (state, action) => {
      state.isErrorModalVisible = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected') && excludeTypes(action),
      (state, action) => {
        console.log(action);
        const token = getToken();
        state.isErrorModalVisible = true && token;
      },
    );
  },
});

export const { setIsErrorModalVisible } = errorSlice.actions;
