import React, { FC } from 'react';

import { NotificationsTable } from './components';
import { Wrapper } from './styles';

const Notifications: FC = () => {
  return (
    <Wrapper>
      <NotificationsTable />
    </Wrapper>
  );
};

export default Notifications;
