import styled from 'styled-components';

interface Props {
  isActive: boolean;
}
export const Wrapper = styled.article`
  width: 100%;
  min-width: fit-content;
  height: fit-content;
  padding: 32px;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  border-top: none;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);

  > hr {
    height: 1px;
    border-color: ${({ theme }) => theme.color.grey200};
    margin-bottom: 32px;
    margin-top: 32px;
  }
`;

export const StyledSection = styled.section`
  display: flex;
`;

export const StepSectionWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  margin-bottom: 80px;
`;

export const StepSection = styled.div`
  padding: 0 150px 20px 0;

  @media screen and (max-width: 1100px) {
    padding-right: 75px;
  }
`;

export const StepItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: -24px;
    left: 16px;
    transform: translateX(-50%);
    width: 1px;
    height: 12px;
    background: ${({ theme }) => theme.color.grey600};
  }

  &:last-child {
    margin: 0;

    &:after {
      display: none;
    }
  }
`;

export const Step = styled.div<Props>`
  min-width: 32px;
  height: 32px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.blue300 : theme.color.white};
  border: ${({ theme, isActive }) =>
    isActive ? 'none' : `1px solid ${theme.color.grey700}`};
  border-radius: 50%;
  color: ${({ theme }) => theme.color.grey900};
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepText = styled.div<Props>`
  color: ${({ theme }) => theme.color.grey900};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 140%;
  margin-left: 10px;
`;
