import styled from 'styled-components';

interface Props {
  fullWidth?: boolean;
}

export const Wrapper = styled.div`
  flex-grow: 1;
  padding: 32px;
  min-height: 100vh;
  background: ${({ theme }) => theme.color.background};
  overflow-y: auto;
`;

export const EditContent = styled.form`
  flex-grow: 1;
  width: 100%;

  > div:first-child {
    display: grid;
    gap: 24px;
    grid-template-columns: calc(50% - 12px) calc(40% - 12px);
  }

  @media screen and (max-width: 1510px) {
    > div:first-child {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  > div {
    section {
      z-index: 2;

      @media screen and (max-width: 1510px) {
        max-width: 464px;
      }
    }
  }
`;

export const InputSection = styled.div<Props>`
  display: grid;
  width: 100%;
  gap: 24px;
  z-index: 1;

  .phone-input-section {
    max-width: 100%;
  }

  &:last-child {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 1510px) {
    grid-template-columns: 464px;
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;
