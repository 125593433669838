import { useTranslation } from 'react-i18next';

export const usePronunciationOptions = () => {
  const { t } = useTranslation();

  return [
    { label: t('male'), value: 'Male' },
    { label: t('female'), value: 'Female' },
  ];
};
