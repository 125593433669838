import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TopicsOfChatsStyled, TableWrapper } from './styles';
import { PrimaryButton } from 'common/components';
import { TopicsTable } from './components';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import AddTopicModal from './components/AddTopicModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  createTopic,
  editTopic,
} from 'applicaiton/store/reducers/Topics/ActionCreators';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { resetActionsTopic } from 'applicaiton/store/reducers/Topics/TopicsSlice';

const TopicsOfChatsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [showAddTopic, setShowAddTopic] = useState(false);
  const userRole = getUserRole();

  const { topicsChanges } = useAppSelector((state) => state.topics);
  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );

  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);

  const userId = useMemo(() => {
    return isClinicOwnerRole
      ? currentClinicOwner?.professional?.id
      : getUserId();
  }, [currentClinicOwner]);

  const handleAddTopicModal = (title: string, clinicIds: string) => {
    dispatch(
      createTopic({
        id: String(userId),
        data: {
          name: title,
          professionalId: String(userId),
          clinicId: clinicIds,
        },
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setShowAddTopic(false);
      }
    });
  };

  const handleEditTopicModal = (
    title: string,
    clinicIds: string,
    topicId: string,
  ) => {
    dispatch(
      editTopic({
        id: String(userId),
        topicId: String(topicId),
        data: {
          name: title,
          clinicId: clinicIds,
        },
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setShowAddTopic(false);
      }
    });
  };

  const handleCloseModal = () => {
    setShowAddTopic(false);
    dispatch(resetActionsTopic());
  };

  return (
    <TopicsOfChatsStyled>
      <TableWrapper>
        <h1>{t('topics_of_chats.title')}</h1>
        <PrimaryButton onClick={() => setShowAddTopic(true)}>
          <AddIcon />
          {t('topics_of_chats.add_new_topic')}
        </PrimaryButton>
      </TableWrapper>
      <TopicsTable openAddModal={() => setShowAddTopic(true)} />
      {(showAddTopic || topicsChanges) && (
        <AddTopicModal
          onClose={handleCloseModal}
          onAdd={handleAddTopicModal}
          onEdit={handleEditTopicModal}
        />
      )}
    </TopicsOfChatsStyled>
  );
};

export default TopicsOfChatsList;
