import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, PrimaryButton, SecondaryButton } from 'common/components';
import {
  Wrapper,
  StyledDivider,
  ButtonsWrapper,
  SubscriptionSection,
  EmptyState,
  TabsSection,
  TabItem,
  ContentSection,
} from './styles';
import { FormProps } from './types';
import EmptySubscriptionSection from '../EmptySubscriptionSection';
import AddSubscriptionModal from '../AddSubscriptionModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import SubscriptionItem from '../SubscriptionItem';
import {
  setProfileChanges,
  setProfileSubscriptions,
} from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import {
  ClinicProfessionalUpdateDto,
  CpSubscriptionCreationDto,
} from '@docbay/schemas';
import { CreateSubscription } from 'applicaiton/store/reducers/Professionals/models';
import { v4 as uuid } from 'uuid';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { EmptyStateIcon } from 'applicaiton/assets';
import { ClinicProfessionalSubscriptionType } from 'common/types/subscriptions';

const ProfessionalSubscriptions: FC<FormProps> = ({
  onBackBtnClick,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    isPrivateDoctor,
    updatePaymentAccountStatus,
    isPaymentAccountActive,
    isLoading,
  } = useClinicOwner();
  const [isOpenAddSubscription, setIsOpenAddSubscription] = useState(false);
  const [currentTab, setCurrentTab] =
    useState<ClinicProfessionalSubscriptionType | null>(null);

  const {
    isLoading: isLoadingProfessionals,
    profileChanges,
    subscriptions,
  } = useAppSelector((state) => state.createProfessionalProfile);

  useEffect(() => {
    if (subscriptions.length && !currentTab) {
      const hasCompanySubscriptions = subscriptions.some(
        (item) =>
          String(item.type) === ClinicProfessionalSubscriptionType.COMPANY,
      );
      setCurrentTab(
        hasCompanySubscriptions
          ? ClinicProfessionalSubscriptionType.COMPANY
          : ClinicProfessionalSubscriptionType.PUBLIC,
      );
    }
  }, [subscriptions]);

  const filteredSubscriptions = useMemo(() => {
    return subscriptions.filter((item) => String(item.type) === currentTab);
  }, [currentTab, subscriptions]);

  useEffect(() => {
    const arr: any = [];

    profileChanges?.clinicsRelations?.map((clinic) => {
      if (clinic.subscriptionsToAdd?.length) {
        clinic.subscriptionsToAdd?.map((subscription) => {
          arr.push({
            id: uuid(),
            clinicId: clinic.clinicId,
            title: subscription.title,
            price: subscription.price!,
            durationInWeeks: subscription.durationInWeeks!,
            teleconsultationMinutes: subscription.teleconsultationMinutes!,
            onlineHours: subscription.onlineHours,
            type: subscription.type as ClinicProfessionalSubscriptionType,
            minNumberOfEmployees: subscription.minNumberOfEmployees,
            maxNumberOfEmployees: subscription.maxNumberOfEmployees,
          });
        });
      }
    });

    dispatch(setProfileSubscriptions(arr));
  }, [profileChanges]);

  useEffect(() => {
    updatePaymentAccountStatus();
  }, []);

  const setSubscriptionIntoClinic = (data: CreateSubscription) => {
    const newClinicsRelations: ClinicProfessionalUpdateDto[] = [];

    profileChanges?.clinicsRelations?.map((clinic) => {
      if (clinic.clinicId === data.clinicId) {
        const newData: CpSubscriptionCreationDto = {
          title: data.title!,
          price: data.price!,
          durationInWeeks: data.durationInWeeks!,
          teleconsultationMinutes: data.teleconsultationMinutes!,
          onlineHours: data.onlineHours,
          type: data.type as ClinicProfessionalSubscriptionType,
          minNumberOfEmployees: data.minNumberOfEmployees,
          maxNumberOfEmployees: data.maxNumberOfEmployees,
        };

        newClinicsRelations.push({
          ...clinic,
          subscriptionsToAdd: [
            ...((clinic.subscriptionsToAdd as CpSubscriptionCreationDto[]) ||
              []),
            newData,
          ],
        });
      } else {
        newClinicsRelations.push(clinic);
      }
    });

    const prof = {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };

    dispatch(setProfileChanges(prof!));
    setIsOpenAddSubscription(false);
  };

  const handleBackBtn = () => {
    onBackBtnClick();
  };

  const handleOpenAddSubscription = () => {
    setIsOpenAddSubscription(true);
  };

  const handleCloseAddSubscription = () => {
    setIsOpenAddSubscription(false);
  };

  const handleDelete = (data: CreateSubscription) => {
    const indexOfObject = subscriptions!.findIndex(
      (object) => object.id === data.id,
    );

    const newSubscriptions = [...subscriptions];
    const newClinicsRelations: ClinicProfessionalUpdateDto[] = [];

    newSubscriptions!.splice(indexOfObject, 1);

    const subs = newSubscriptions.filter(
      (item) => item.clinicId === data.clinicId,
    );

    const newSubs = subs.map((item) => {
      return {
        title: item.title!,
        price: Number(item.price)!,
        durationInWeeks: item.durationInWeeks!,
        teleconsultationMinutes: item.teleconsultationMinutes!,
        onlineHours: item.onlineHours,
        type: item.type as ClinicProfessionalSubscriptionType,
        minNumberOfEmployees: item.minNumberOfEmployees,
        maxNumberOfEmployees: item.maxNumberOfEmployees,
      };
    });

    profileChanges?.clinicsRelations?.map((clinic) => {
      if (clinic.clinicId === data.clinicId) {
        newClinicsRelations.push({
          ...clinic,
          subscriptionsToAdd: newSubs,
        });
      } else {
        newClinicsRelations.push(clinic);
      }
    });

    const prof = {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };

    dispatch(setProfileChanges(prof!));
  };

  const isFilteredListEmpty = useMemo(() => {
    return !!subscriptions.length && !filteredSubscriptions.length;
  }, [subscriptions, filteredSubscriptions]);

  const isShowEmptyState = useMemo(() => {
    return (
      (isPaymentAccountActive && !subscriptions.length) || isFilteredListEmpty
    );
  }, [isPaymentAccountActive, subscriptions, isFilteredListEmpty]);

  return (
    <Wrapper>
      <ContentSection>
        {!isPaymentAccountActive && (
          <EmptySubscriptionSection
            onRefreshPaymentAccountStatus={updatePaymentAccountStatus}
          />
        )}
        {!!subscriptions.length && isPaymentAccountActive && (
          <SubscriptionSection>
            <p>{t('professional_profile.subscriptions.subscription_list')}</p>
            <TabsSection>
              <TabItem
                active={
                  currentTab === ClinicProfessionalSubscriptionType.PUBLIC
                }
                onClick={() =>
                  setCurrentTab(ClinicProfessionalSubscriptionType.PUBLIC)
                }
              >
                {t('subscriptions.public_subscriptions')}
              </TabItem>
              <TabItem
                active={
                  currentTab === ClinicProfessionalSubscriptionType.COMPANY
                }
                onClick={() =>
                  setCurrentTab(ClinicProfessionalSubscriptionType.COMPANY)
                }
              >
                {t('subscriptions.company_subscriptions')}
              </TabItem>
            </TabsSection>
            {filteredSubscriptions?.map((item, index) => {
              return (
                <SubscriptionItem
                  key={index}
                  data={item}
                  onDelete={handleDelete}
                />
              );
            })}
            {!isFilteredListEmpty && (
              <SecondaryButton onClick={handleOpenAddSubscription}>
                <AddIcon />
                {t('professional_profile.subscriptions.add_subscription')}
              </SecondaryButton>
            )}
          </SubscriptionSection>
        )}
        {isShowEmptyState && (
          <EmptyState
            className={isFilteredListEmpty ? 'filtered-list-empty' : ''}
          >
            <EmptyStateIcon />
            <p>{t('professional_profile.subscriptions.empty')}</p>
            <SecondaryButton onClick={handleOpenAddSubscription}>
              <AddIcon />
              {t('professional_profile.subscriptions.add_subscription')}
            </SecondaryButton>
          </EmptyState>
        )}
      </ContentSection>
      <div>
        <StyledDivider />
        <ButtonsWrapper>
          <SecondaryButton onClick={handleBackBtn}>{t('back')}</SecondaryButton>
          <PrimaryButton
            type={'button'}
            onClick={() => onSave()}
            disabled={isLoadingProfessionals}
          >
            {isPrivateDoctor
              ? t('save')
              : t('professional_profile.send_invite')}
          </PrimaryButton>
        </ButtonsWrapper>
      </div>
      {isOpenAddSubscription && (
        <AddSubscriptionModal
          key={'add'}
          isOpen={isOpenAddSubscription}
          onClose={handleCloseAddSubscription}
          onAdd={setSubscriptionIntoClinic}
          type={currentTab}
        />
      )}

      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default ProfessionalSubscriptions;
