import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setClinicOwnerRegistrationSessionStorage } from 'applicaiton/sessionStorage/clinicRegistration';
import { setIsSubscriptionsFree } from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';

import Switch from 'common/components/Switch';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import { PackFeatures } from 'features/feature-clinic-registration/constants';
import { shouldPay } from 'features/feature-clinic-registration/helpers/shouldPay';

import Counter from '../Counter';
import Features from '../Features';
import OnlinePackage from '../OnlinePackage';
import PayPeriodSwitcher from '../PayPeriodSwitcher';

import { PackageProps } from './models';
import {
  PackageInfoStyled,
  Wrapper,
  MainPrice,
  AdditionalPrice,
  PriceSwitcher,
  Switcher,
  PeriodName,
  SwitchersSection,
} from './styles';

const Package: FC<PackageProps> = ({
  quantity,
  handleSetQuantity,
  id,
  title,
  isActive,
  onClick,
  isPrivateDoctor,
  packageType,
  prices,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [isYearly, setIsYearly] = useState(false);
  const [isInvoicing, setIsInvoicing] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const { subscriptions } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );

  const invoicingSubscriptionPrices = useMemo(() => {
    const invoicingSubscription = subscriptions?.find(
      (item) => String(item.type) === PackFeatures.invoicingFeature,
    );
    const price = invoicingSubscription?.prices?.find(
      (price) => price?.recurringInterval === (isYearly ? 'year' : 'month'),
    );

    return +(price?.unitAmountDecimal || 0) / 100 || 0;
  }, [subscriptions, isYearly]);

  const description = useMemo(() => {
    return isPrivateDoctor
      ? t('packs_description.private_doctor')
      : t('packs_description.other_packs');
  }, [t, i18n.language]);

  useEffect(() => {
    if (!isActive) {
      setIsYearly(false);
      setIsInvoicing(false);
      setIsEnabled(false);
    }
  }, [isActive]);

  const handlePeriodChange = (value: boolean) => {
    setIsYearly(value);
    setIsEnabled(value);
  };

  const handleChangeInvoicing = (value: boolean) => {
    setIsInvoicing(value);
    setClinicOwnerRegistrationSessionStorage('isInvoicing', value);

    const invoicingSubscription = subscriptions?.find(
      (item) => String(item.type) === PackFeatures.invoicingFeature,
    );

    setClinicOwnerRegistrationSessionStorage(
      'subscriptionInvoicingPackages',
      value ? invoicingSubscription : {},
    );
  };

  useEffect(() => {
    const isSubscriptionsFree: boolean = !shouldPay();
    dispatch(setIsSubscriptionsFree(isSubscriptionsFree));
  }, [isInvoicing, isYearly]);

  const handleChangeActivePackage = () => {
    onClick(id);
    handleChangeInvoicing(false);
  };

  const currentPrice = useMemo(() => {
    return prices.find(
      (price) => price?.recurringInterval === (isYearly ? 'year' : 'month'),
    );
  }, [prices, isYearly]);

  const currentMainPrice = useMemo(() => {
    if (!currentPrice?.tiers?.length) {
      return 0;
    }

    return currentPrice?.tiers[0]?.unitAmountDecimal
      ? (+currentPrice?.tiers[0]?.unitAmountDecimal || 0) / 100
      : 0;
  }, [currentPrice]);

  const currentAdditionalPrice = useMemo(() => {
    if ((currentPrice?.tiers?.length || 0) < 2) {
      return 0;
    }

    return currentPrice?.tiers?.[1]?.unitAmountDecimal
      ? (+currentPrice?.tiers[1]?.unitAmountDecimal || 0) / 100
      : 0;
  }, [currentPrice]);

  return (
    <Wrapper
      onClick={() => !isActive && handleChangeActivePackage()}
      isActive={isActive}
    >
      <div>
        <PackageInfoStyled>
          <div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </PackageInfoStyled>
      </div>
      {isPrivateDoctor ? (
        <OnlinePackage
          isActive={isActive}
          isYearly={isYearly}
          isEnabled={isEnabled}
          onChange={handlePeriodChange}
          onChangeInvoicing={handleChangeInvoicing}
        />
      ) : (
        <div>
          <Counter
            value={quantity}
            handleSetQuantity={handleSetQuantity}
            id={id}
          />
          <div>
            <MainPrice>
              <span>{currentMainPrice} €</span>{' '}
              {isYearly ? t('per_doctor_year') : t('per_doctor_month')}
            </MainPrice>
            <AdditionalPrice>
              {currentAdditionalPrice} €{t('each_additional_doctor')}
            </AdditionalPrice>
          </div>
          <SwitchersSection>
            <PayPeriodSwitcher
              isActive={isActive}
              isYearly={isYearly}
              isEnabled={isEnabled}
              onChange={handlePeriodChange}
            />
            {String(packageType) === PackFeatures.clinicFullPack && (
              <PriceSwitcher>
                <Switcher>
                  <PeriodName isActive={isInvoicing}>
                    {t('invoicing_feature')}
                  </PeriodName>
                  <Switch
                    checked={isInvoicing}
                    onChange={(value) => handleChangeInvoicing(value)}
                  />
                </Switcher>
                <AdditionalPrice>
                  {invoicingSubscriptionPrices} €{' '}
                  {isYearly ? t('per_year') : t('per_month')}
                </AdditionalPrice>
              </PriceSwitcher>
            )}
          </SwitchersSection>
          <Features packageType={packageType} />
        </div>
      )}
    </Wrapper>
  );
};

export default Package;
