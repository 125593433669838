import React, { FC, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import {
  StyledModal,
  Title,
  StyledCloseIcon,
  SubscriptionInfo,
  Row,
  ButtonWrapper,
  RadioButtonSection,
} from './styles';
import {
  DatePicker,
  Input,
  RadioButton,
  SecondaryButton,
} from 'common/components';
import { useTranslation } from 'react-i18next';
import { useFamilyMemberForm } from 'features/feature-patient-profile/hooks/useFamilyMemberForm';
import PrimaryButton from 'common/components/PrimaryButton';
import {
  FamilyMemberCreationDto,
  FamilyMemberResponseDto,
} from '@docbay/schemas';
import { usePronunciationOptions } from 'common/hooks/usePronunciationOptions';
import dayjs from 'dayjs';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  editMode: boolean;
  onEdit?: ({
    memberId,
    data,
  }: {
    memberId: string;
    data: FamilyMemberCreationDto;
  }) => void;
  onAdd: (data: FamilyMemberCreationDto) => void;
  data: FamilyMemberResponseDto | null;
}
const AddFamilyMemberModal: FC<Props> = ({
  onClose,
  onAdd,
  isOpen,
  data,
  editMode,
  onEdit,
}) => {
  const { t } = useTranslation();
  const [pronunciation, setPronunciation] = useState('Male');
  const pronunciations = usePronunciationOptions();

  const {
    setValue,
    errors,
    getValues,
    watch,
    handleSubmit,
    register,
    clearErrors,
    reset,
  } = useFamilyMemberForm({
    requiredError: t('errors.required'),
    numberError: t('errors.number_error'),
    lengthFinError: t('errors.lengthFinError'),
    lengthSsnError: t('errors.lengthSsnError'),
  });

  useEffect(() => {
    if (data) {
      setValue('firstName', String(data.firstName));
      setValue('lastName', data.lastName);
      setValue('dateOfBirth', String(data.dateOfBirth));
      setValue('relationship', data.relationship);
      setValue('socialSecurityNumber', data?.socialSecurityNumber || '');
      setValue('nif', data?.nif || '');

      setPronunciation(data.pronunciation);
    }
  }, [data]);

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  const onSubmit = async () => {
    const {
      firstName,
      lastName,
      relationship,
      dateOfBirth,
      socialSecurityNumber,
      nif,
    } = getValues();

    if (editMode) {
      onEdit &&
        onEdit({
          memberId: data?.id!,
          data: {
            firstName,
            lastName,
            relationship,
            dateOfBirth: dayjs(dateOfBirth).utc(true).format(),
            pronunciation,
            ...(socialSecurityNumber ? { socialSecurityNumber } : {}),
            ...(nif ? { nif } : {}),
          },
        });
    } else {
      onAdd({
        firstName,
        lastName,
        relationship,
        dateOfBirth: dayjs(dateOfBirth).utc(true).format(),
        pronunciation,
        ...(socialSecurityNumber ? { socialSecurityNumber } : {}),
        ...(nif ? { nif } : {}),
      });
    }
  };

  const handleSetChanges = (value: string) => {
    setValue('dateOfBirth', value);
    clearErrors('dateOfBirth');
  };

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <Title>
          {t('family_members.add_family_member')}
          <StyledCloseIcon onClick={handleCloseModal} />
        </Title>
        <SubscriptionInfo>
          <RadioButtonSection>
            <p>{t('gender') || ''}</p>
            <div>
              {pronunciations.map((item) => (
                <RadioButton
                  key={item.value}
                  id={item.value}
                  label={item.label}
                  checked={pronunciation === item.value}
                  onChange={(e) => setPronunciation(e.target.id)}
                />
              ))}
            </div>
          </RadioButtonSection>
          <Row>
            <Input
              type="text"
              id={'firstName'}
              label={t('family_members.first_name') || ''}
              placeholder={t('family_members.first_name_placeholder') || ''}
              register={register}
              errorMessage={errors?.firstName?.message}
            />
            <Input
              type="text"
              id={'lastName'}
              label={t('family_members.last_name') || ''}
              placeholder={t('family_members.last_name_placeholder') || ''}
              register={register}
              errorMessage={errors?.lastName?.message}
            />
          </Row>
          <Input
            label={t('family_members.table_relationship') || ''}
            id={'relationship'}
            type="text"
            register={register}
            errorMessage={errors?.relationship?.message}
            placeholder={t('family_members.relationship_placeholder') || ''}
          />
          <DatePicker
            id={'dateOfBirth'}
            label={t('patient.date_birth') || ''}
            value={watch('dateOfBirth')}
            onChange={(value) => {
              handleSetChanges(value);
            }}
            maxDate={Date.now()}
            minDate={0}
            format={'DD/MM/YYYY'}
            errorMessage={errors?.dateOfBirth?.message}
          />
          <Input
            label={t('family_members.fin') || ''}
            id={'nif'}
            type="text"
            register={register}
            errorMessage={errors?.nif?.message}
            placeholder={t('family_members.fin_placeholder') || ''}
          />
          <Input
            label={t('family_members.ssn') || ''}
            id={'socialSecurityNumber'}
            type="text"
            register={register}
            errorMessage={errors?.socialSecurityNumber?.message}
            placeholder={t('family_members.ssn_placeholder') || ''}
          />
        </SubscriptionInfo>
        <ButtonWrapper>
          <SecondaryButton onClick={handleCloseModal}>
            {t('cancel')}
          </SecondaryButton>
          <PrimaryButton
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={!!Object.keys(errors).length}
          >
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </StyledModal>
    </Modal>
  );
};

export default AddFamilyMemberModal;
