import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { PathNames } from 'applicaiton/routes';

import { Loader, PrimaryButton } from 'common/components';
import { useAppSelector } from 'common/hooks/redux';
import PageSwitcher from 'common/components/PageSwitcher';
import { featuresPermissions } from 'common/helpers/featuresPermissions';

import {
  PatientsTable,
  InfoSection,
  AddPatientModal,
  SuccessModal,
} from './components';
import { LoaderContainer, UsersListStyled, Container } from './styles';

const MyPatients: FC = () => {
  const { t } = useTranslation();
  const { isLoading, total } = useAppSelector((state) => state.patients);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { hasWaitingListPermission, hasAppointmentRequestPermission } =
    featuresPermissions();

  const showSwitcher =
    hasAppointmentRequestPermission || hasWaitingListPermission;

  const handleOpenModal = () => {
    setShowAddModal(true);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const handleOpenSuccessModal = () => {
    setShowSuccessModal(true);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <UsersListStyled>
      {isLoading && (
        <LoaderContainer open={isLoading}>
          <>
            <Loader />
          </>
        </LoaderContainer>
      )}
      {showSwitcher && (
        <PageSwitcher>
          <div>{t('clinicsConfiguration.aside_menu.my_patients_path')}</div>
          {hasWaitingListPermission && (
            <Link to={PathNames.waitingList}>
              {t('clinicsConfiguration.aside_menu.waiting_list')}
            </Link>
          )}
          {hasAppointmentRequestPermission && (
            <Link to={PathNames.requestList}>
              {t('clinicsConfiguration.aside_menu.request_list')}
            </Link>
          )}
        </PageSwitcher>
      )}
      <Container>
        <InfoSection patientAvailable={total} setCurrentPage={setCurrentPage} />
        <div>
          <PrimaryButton onClick={handleOpenModal}>
            <AddIcon />
            {t('my_patients.add_new_patient')}
          </PrimaryButton>
        </div>
      </Container>

      <PatientsTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleShowModal={handleOpenModal}
      />

      <AddPatientModal
        isOpen={showAddModal}
        onClose={handleCloseModal}
        handleOpenSuccessModal={handleOpenSuccessModal}
      />
      {showSuccessModal && (
        <SuccessModal
          open={showSuccessModal}
          onClose={handleCloseSuccessModal}
        />
      )}
    </UsersListStyled>
  );
};

export default MyPatients;
