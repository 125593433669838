import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px 32px 20px 32px;
  background: ${({ theme }) => theme.color.background};
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;
