import styled from 'styled-components';

export const SectionStyled = styled.section`
  height: inherit;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: -20px;

  & > div {
    & > p {
      width: 100%;
      position: relative;
      bottom: 20px;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      text-align: right;
      color: ${({ theme }) => theme.color.grey700};
      font-style: normal;
    }

    & > button {
      height: 48px;
    }

    &:last-of-type {
      background: ${({ theme }) => theme.color.white};
      position: sticky;
      bottom: -32px;
      padding: 20px 0;
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
    cursor: pointer;

    thead {
      position: relative;
      bottom: 20px;

      th:first-child {
        text-align: start;
        padding-left: 88px;
      }

      th:nth-child(2) {
        width: 80px;
        text-align: center;
      }

      th:nth-child(3) {
        width: 123px;
      }

      th:last-child {
        width: 90px;
        padding-right: 32px;
      }
    }

    tbody {
      position: relative;
      bottom: 28px;
    }
  }

  tr {
    td {
      border-top: solid 1px;
      border-bottom: solid 1px;
      padding-top: 29px;
      padding-bottom: 29px;
      background-color: transparent;
      border-color: ${({ theme }) => theme.color.grey300};
    }
  }

  tbody {
    tr td:first-child {
      border: 1px solid;
      border-color: ${({ theme }) => theme.color.grey300};
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: 0px;
      padding-left: 32px;
      text-align: start;
    }

    tr td:last-child {
      border: 1px solid;
      border-color: ${({ theme }) => theme.color.grey300};
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: 0px;
      padding-right: 32px;
    }

    tr:first-child td {
      border-top-style: solid;
    }

    tr td:first-child {
      border-left-style: solid;
    }
  }
`;

export const SubscriptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  margin-bottom: 32px;

  @media screen and (min-width: 1440px) {
    display: flex;
  }
`;
