import styled from 'styled-components';

export const UsersListStyled = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px 32px 20px 32px;
  background: ${({ theme }) => theme.color.background};
  overflow-y: auto;
  height: 100%;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      text-decoration: none;
      > button {
        width: fit-content;
        padding: 10px 24px;
        > svg {
          path {
            fill: ${({ theme }) => theme.color.grey900};
          }
        }
      }
    }
  }
  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 28px;
    font-weight: 700;
    line-height: 39.2px;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }
`;
