import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DatePicker,
  Input,
  InputPhone,
  PrimaryButton,
  RadioButton,
  SecondaryButton,
} from 'common/components';
import { usePronunciationOptions } from 'common/hooks/usePronunciationOptions';
import { ButtonSection, FormStyled, RadioButtonSection, Row } from './styles';
import { PatientFormProps } from './modules';

const PatientForm: FC<PatientFormProps> = ({
  handleSubmit,
  onSubmit,
  pronunciation,
  setPronunciation,
  register,
  watch,
  setValue,
  errors,
  handleCloseModal,
  isStateChanged,
}) => {
  const { t } = useTranslation();
  const pronunciations = usePronunciationOptions();
  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <div>
        <RadioButtonSection>
          <p>{t('gender') || ''}</p>
          <div>
            {pronunciations.map((item) => (
              <RadioButton
                key={item.value}
                id={item.value}
                label={item.label}
                checked={pronunciation === item.value}
                onChange={(e) => setPronunciation(e.target.id)}
              />
            ))}
          </div>
        </RadioButtonSection>
        <Row>
          <Input
            label={t('personal_information.first_name') || ''}
            id={'firstName'}
            type="text"
            register={register}
            errorMessage={errors.firstName?.message}
            placeholder={t('patient.new_patient_name') || ''}
          />
          <Input
            label={t('personal_information.last_name') || ''}
            id={'lastName'}
            type="text"
            register={register}
            errorMessage={errors.lastName?.message}
            placeholder={t('patient.new_patient_lastname') || ''}
          />
        </Row>
        <Row>
          <InputPhone
            id="phone"
            label={t('phone') || ''}
            value={watch('phone')}
            register={register}
            errorMessage={errors.phone?.message}
            isValid={true}
          />
          <DatePicker
            id={'dateOfBirth'}
            label={t('patient.date_birth') || ''}
            value={watch('dateOfBirth')}
            onChange={(value) => setValue('dateOfBirth', value)}
            maxDate={Date.now()}
            minDate={0}
            format={'DD/MM/YYYY'}
            errorMessage={errors?.dateOfBirth?.message}
          />
        </Row>
        <Row fullWidth>
          <Input
            id="email"
            label={t('email_optional') || ''}
            type="email"
            placeholder={t('patient.new_patient_email') || ''}
            register={register}
            errorMessage={errors?.email?.message}
          />
        </Row>
        <Input
          id="nif"
          label={t('my_patients.fiscal_identification_number') || ''}
          type="text"
          register={register}
          errorMessage={errors?.nif?.message}
        />
        <Input
          id="socialSecurityNumber"
          label={t('my_patients.social_security_number') || ''}
          type="text"
          register={register}
          errorMessage={errors?.socialSecurityNumber?.message}
        />
      </div>
      <ButtonSection>
        <SecondaryButton type="button" onClick={handleCloseModal}>
          {t('cancel')}
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={!isStateChanged}>
          {t('save')}
        </PrimaryButton>
      </ButtonSection>
    </FormStyled>
  );
};

export default PatientForm;
