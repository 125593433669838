import React, { FC, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  AgendaView,
  CalendarView,
  Format,
  ViewBy,
} from 'applicaiton/constants/scheduler';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  AgendaList,
  LayoutComponent,
  RefreshAgendaModal,
  SchedulerByProfessional,
} from './components';
import NoDataElement from './components/NoDataElement';
import { Loader } from 'common/components';
import SchedulerByAvailability from './components/SchedulerByAvailability';
import { SchedulerWrapper } from './styles';
import { getDayHours } from './helpers/getDayHours';
// import { resetCurrentAppointment } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice'; Remove after success testing new reserve modal
import {
  refreshAgenda,
  resetScheduler,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { PathNames } from 'applicaiton/routes';
import {
  fromUtcToDefaultTimezone,
  getDateInDefaultTimezone,
} from 'common/helpers/dateTimeHelper';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

const grouping = [
  {
    resourceName: 'priorityId',
  },
];

const Agenda: FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const userRole = getUserRole();
  const location = useLocation();
  const currentLanguage = i18n.language.includes('pt')
    ? 'pt-PT'
    : i18n.language.includes('en')
    ? 'en-GB'
    : 'fr-FR';
  const { isLoading, agenda } = useAppSelector((state) => state.agenda);
  const { tz } = useUserTimeZone();
  const { clinicsByOwnerId } = useAppSelector((state) => state.clinics);
  const {
    isLoading: isLoadingScheduler,
    byProfessionals,
    byAvailability,
    viewBy,
    isAddNewEvent,
  } = useAppSelector((state) => state.scheduler);
  const { currentAppointment, isHighlighted } = useAppSelector(
    (state) => state.patientAppointments,
  );

  const [timeToRefreshAgenda, setTimeToRefreshAgenda] = useState(false);
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const isProfessional = userRole === UserRoles.professional;
  const isViewByAvailability = useMemo(
    () => viewBy === ViewBy.byAvailability,
    [viewBy],
  );
  const calendarView = useMemo(
    () =>
      isViewByAvailability
        ? byAvailability.calendarView
        : byProfessionals.calendarView,
    [byAvailability.calendarView, byProfessionals.calendarView],
  );
  const currentView = isViewByAvailability ? byAvailability : byProfessionals;
  const isListView = currentView.agendaView === AgendaView.list;
  const isWeeklyView = calendarView === CalendarView.weekly;
  const selectedDate = isViewByAvailability
    ? byAvailability.selectedDate
    : byProfessionals.selectedDate;
  const daysCount = isViewByAvailability
    ? byAvailability.daysCount
    : byProfessionals.daysCount;
  const agendaProfessionals = isViewByAvailability
    ? byAvailability.agendaProfessionals
    : byProfessionals.agendaProfessionals;
  const slotsTimeView = isViewByAvailability
    ? byAvailability.slotsTimeView
    : byProfessionals.slotsTimeView;

  const dayHour = useMemo(() => getDayHours(slotsTimeView), [slotsTimeView]);

  let timeout: NodeJS.Timeout | null = null;
  const fiveMinutes = 1000 * 60 * 5;

  const showRefreshModal = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setTimeToRefreshAgenda(true);
    }, fiveMinutes);
  };

  const onMouseMove = () => {
    showRefreshModal();
  };

  useEffect(() => {
    if (agendaProfessionals.length) {
      showRefreshModal();

      // listen for mouse events
      window.addEventListener('mousemove', onMouseMove);

      // cleanup
      return () => {
        if (timeout) {
          clearTimeout(timeout);
          window.removeEventListener('mousemove', onMouseMove);
        }
      };
    }
  });

  const appointments = useMemo(() => {
    const data = agenda.map((item) => ({
      appointments: item.agenda.appointments.map((appointment) => ({
        id: appointment.id,
        startDate: fromUtcToDefaultTimezone(appointment.startDate),
        endDate: fromUtcToDefaultTimezone(appointment.endDate),
        appointmentType: appointment.appointmentType.title,
        meetingPlace: appointment.appointmentType.meetingPlace,
        availableForOnlineBooking:
          appointment.appointmentType.availableForOnlineBooking,
        colorCode: appointment.appointmentType.colorCode,
        priorityId: item.professional.id,
        highLight: appointment.id === currentAppointment?.id && isHighlighted,
        patientName: `${appointment.patient?.firstName} ${appointment.patient?.lastName}`,
        patientPhoneNumber: appointment.patient?.phone,
        isShowClinicName: agenda?.length > 1,
        clinicName: item.clinic.name,
        bookedForMember: appointment.bookedForMember,
        clinicOwnerId: appointment.clinic?.owner?.id,
      })),
    }));

    return data.map((item) => [...item.appointments]).flat(1);
  }, [agenda, currentAppointment, isHighlighted, tz]);

  const absences = useMemo(() => {
    const data = agenda.map((item) => ({
      absences: item.agenda.absences.map((absence) => ({
        id: absence.id,
        startDate: fromUtcToDefaultTimezone(absence.startDate),
        endDate: fromUtcToDefaultTimezone(absence.endDate),
        priorityId: item.professional.id,
        reason: absence.reason || '',
        clinicOwnerId: absence.clinic?.owner?.id,
      })),
    }));

    return data.map((item) => [...item.absences]).flat(1);
  }, [agenda, tz]);

  const confusingAgendas = useMemo(() => {
    const data = agenda.map((item) => ({
      appointments: item.agenda.appointments.filter(
        (appointment) =>
          appointment.confusingAgenda?.appointments?.length ||
          appointment.confusingAgenda?.absences?.length,
      ),
      absences: item.agenda.absences.filter(
        (absence) =>
          absence.confusingAgenda?.appointments?.length ||
          absence.confusingAgenda?.absences?.length,
      ),
    }));

    return data;
  }, [agenda, tz]);

  const resources = useMemo(() => {
    const professionalInstances = agendaProfessionals.map((item) => ({
      id: item.id!,
      firstName: item.firstName!,
      lastName: item.lastName!,
    }));

    return [
      {
        fieldName: 'priorityId',
        title: 'Priority',
        instances: professionalInstances,
      },
    ];
  }, [agendaProfessionals]);

  const getWorkingHours = (date: Date) => {
    const dateString = getDateInDefaultTimezone(date).format(
      Format.dateTimeFormat,
    );
    return new Date(dateString);
  };

  const workingHours = useMemo(() => {
    return agenda
      .map((item) =>
        item.agenda.scheduleDateRange.map((dateRange) => ({
          startDate: getWorkingHours(dateRange.startDate),
          endDate: getWorkingHours(dateRange.endDate),
          priorityId: item.professional.id,
        })),
      )
      .flat(1);
  }, [agenda, clinicsByOwnerId, tz]);

  const clinicWorkingHours = useMemo(() => {
    if (isProfessional || isMyAgendaPage) return null;
    return agenda
      .map((item) =>
        item.agenda.clinicScheduleDateRange.map((item) => ({
          startDate: getWorkingHours(item.startDate),
          endDate: getWorkingHours(item.endDate),
        })),
      )
      .flat(1);
  }, [agenda, clinicsByOwnerId, isProfessional, isMyAgendaPage, tz]);

  const showAgenda = useMemo(
    () => agendaProfessionals.some((item) => item?.id),
    [agendaProfessionals],
  );

  const isOneProfessional =
    showAgenda && agendaProfessionals.length === 1 && !isViewByAvailability;
  const isMultipleProfessionals =
    showAgenda && (isViewByAvailability || agendaProfessionals.length > 1);
  const isSingleView =
    agendaProfessionals.length === 1 && !isViewByAvailability && !isWeeklyView;

  // Remove after success testing new reserve modal
  // const handleCloseModal = () => {
  //   dispatch(resetCurrentAppointment());
  // };

  const handleRefreshAgenda = () => {
    dispatch(refreshAgenda());
    setTimeToRefreshAgenda(false);
  };

  useEffect(() => {
    return () => {
      dispatch(resetScheduler());
    };
  }, []);

  return (
    <LayoutComponent>
      {(isLoading || isLoadingScheduler) && <Loader />}
      <RefreshAgendaModal
        opened={timeToRefreshAgenda}
        onSubmit={handleRefreshAgenda}
      />
      <SchedulerWrapper
        isSingleView={isSingleView}
        isLoading={isLoading || isLoadingScheduler}
      >
        {showAgenda && isListView ? (
          <AgendaList
            appointments={appointments}
            absences={absences}
            confusingAgendas={confusingAgendas}
            isViewByAvailability={isViewByAvailability}
          />
        ) : (
          <>
            {isMultipleProfessionals && (
              <SchedulerByAvailability
                appointments={appointments}
                absences={absences}
                currentLanguage={currentLanguage}
                grouping={grouping}
                workingHours={workingHours}
                resources={resources}
                dayHour={dayHour}
                selectedDate={selectedDate}
                daysCount={daysCount}
                viewBy={viewBy}
                calendarView={calendarView}
                isAddNewEvent={isAddNewEvent}
                clinicWorkingHours={clinicWorkingHours}
              />
            )}
            {isOneProfessional && (
              <SchedulerByProfessional
                appointments={appointments}
                absences={absences}
                currentLanguage={currentLanguage}
                grouping={grouping}
                workingHours={workingHours}
                resources={resources}
                dayHour={dayHour}
                selectedDate={selectedDate}
                calendarView={calendarView}
                isAddNewEvent={isAddNewEvent}
                clinicId={
                  isViewByAvailability
                    ? byAvailability.clinicId
                    : byProfessionals.clinicId
                }
                clinicWorkingHours={clinicWorkingHours}
              />
            )}
          </>
        )}
        {!showAgenda && <NoDataElement />}
      </SchedulerWrapper>
    </LayoutComponent>
  );
};

export default Agenda;
