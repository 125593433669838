import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClinicProfessionalUpdateDto,
  ProfessionalCreationDto,
  ProfessionalUpdateWarningDto,
} from '@docbay/schemas';
import { useNavigate } from 'react-router';

import {
  Profile,
  getProfilesFromSessionStorage,
  getUserId,
} from 'applicaiton/sessionStorage/auth';
import {
  createClinicOwnerProfessional,
  createProfessional,
  updateProfessional,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { usersRefreshToken } from 'applicaiton/store/reducers/Users/ActionCreators';
import { getClinicOwnerById } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import {
  setProfileSubscriptions,
  setProfileChanges,
  setCreatedProfessionalProfile,
} from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { ReactComponent as CheckIcon } from 'applicaiton/assets/checked.svg';
import { PathNames } from 'applicaiton/routes';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';

import { ProfessionalUpdateWarningsModal } from 'common/components/ProfessionalUpdateWarningsModal';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { featuresPermissions } from 'common/helpers/featuresPermissions';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader } from 'common/components';

import ProfessionalAddForm from '../ProfessionalAddForm';
import GeneralProfileInfo from '../GeneralProfileInfo';
import ClinicProfile from '../ClinicProfile';
import ProfessionalSubscriptions from '../ProfessionalSubscriptions';
import SuccessModal from '../SuccessModal';

import { LayoutComponentProps } from './modules';
import {
  Wrapper,
  StyledSection,
  StepSection,
  StepItem,
  Step,
  StepText,
  StepSectionWrapper,
} from './styles';

const LayoutComponent: FC<LayoutComponentProps> = ({
  isClinicOwnerProfile,
}) => {
  const { t } = useTranslation();
  const userId = getUserId();
  const { isPrivateDoctor } = useClinicOwner();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { clinics } = useAppSelector((state) => state.clinics);
  const {
    isLoading,
    profileChanges,
    existingProfessional,
    isCreatedProfessionalProfile,
  } = useAppSelector((state) => state.createProfessionalProfile);
  const {
    isLoading: isProfessionalUpdateLoading,
    isUpdatedProfessionalProfile,
  } = useAppSelector((state) => state.professionalProfile);

  const [activeForm, setActiveForm] = useState<number>(0);
  const [addedClinics, setClinics] = useState<string[]>([]);
  const [saveProfessionalWarnings, setSaveProfessionalWarnings] = useState<
    ProfessionalUpdateWarningDto[]
  >([]);

  useEffect(() => {
    const professionalAdd: HTMLDivElement | null =
      document.querySelector('#professional-add');

    professionalAdd?.scroll(0, 0);
  }, [activeForm]);

  const subscriptionStep = useMemo(() => {
    return addedClinics.length + 3;
  }, [addedClinics]);

  const showSubscriptionStep = useMemo(() => {
    const hasAccessToSubscription = clinics.some((clinic) => {
      const { hasDocboxSubscriptionConfigurationPermission } =
        featuresPermissions(clinic.owner?.id);

      return hasDocboxSubscriptionConfigurationPermission;
    });

    return hasAccessToSubscription;
  }, [addedClinics, clinics, featuresPermissions]);

  const handleAddClinic = () => {
    setClinics((prev) => [
      ...prev,
      prev.length === 0
        ? t('professional_profile.new_clinic')
        : `${t('professional_profile.new_clinic')} ${prev.length + 1}`,
    ]);
    setActiveForm((prev) => prev + 1);
  };

  const handleNextBtn = () => {
    setActiveForm((prev) => prev + 1);
  };

  const handleNextGeneralBtn = () => {
    setActiveForm((prev) => prev + 1);

    if (!addedClinics.length) {
      setClinics((prev) => [
        ...prev,
        prev.length === 0
          ? t('professional_profile.new_clinic')
          : `${t('professional_profile.new_clinic')} ${prev.length + 1}`,
      ]);
    }
  };

  const handleBackBtn = () => {
    setActiveForm((prev) => prev - 1);
  };

  const handleRemoveClinic = (index: number) => {
    const filteredClinics: string[] = [...addedClinics].filter(
      (_, i) => index !== i,
    );

    const renamedClinics = filteredClinics.map(
      (_item: string, index: number) => {
        if (index === 1) {
          return t('professional_profile.new_clinic');
        } else {
          return `${t('professional_profile.new_clinic')} ${index + 1}`;
        }
      },
    );

    setActiveForm((prev) => {
      return addedClinics.length >= prev ? prev : prev - 1;
    });

    setClinics(renamedClinics);
  };

  const setClinicActiveForPrivateDoctor = (
    profileChanges: ProfessionalCreationDto,
  ) => {
    if (!isPrivateDoctor) {
      return profileChanges;
    }
    const newClinicsRelations: ClinicProfessionalUpdateDto[] =
      profileChanges?.clinicsRelations?.map((clinic) => ({
        ...clinic,
        active: true,
      })) || [];
    return {
      ...profileChanges!,
      clinicsRelations: newClinicsRelations,
    };
  };

  const handleSaveProfessionalResponse = (response: any) => {
    if (response.meta.requestStatus !== 'fulfilled') {
      return;
    }
    if (response.payload?.warnings) {
      console.log('warnings:', response.payload?.warnings);
      setSaveProfessionalWarnings(response.payload?.warnings);
    } else {
      dispatch(setCreatedProfessionalProfile(true));
    }
    dispatch(setProfileSubscriptions([]));
  };

  const onSave = async (professionalData?: ProfessionalCreationDto) => {
    const currentProfessionalData = professionalData
      ? professionalData
      : profileChanges;
    if (existingProfessional) {
      const response = await dispatch(
        updateProfessional({
          id: existingProfessional.id,
          professional: currentProfessionalData!,
        }),
      );
      handleSaveProfessionalResponse(response);
    } else {
      if (isClinicOwnerProfile) {
        const profileToSave = setClinicActiveForPrivateDoctor(
          currentProfessionalData!,
        );
        const response = await dispatch(
          createClinicOwnerProfessional(profileToSave),
        );
        if (response.meta.requestStatus === 'fulfilled') {
          const profile: Profile[] | null = getProfilesFromSessionStorage();
          if (profile) {
            dispatch(
              usersRefreshToken({
                refreshToken: profile[0].refreshToken,
                email: profile[0].email,
              }),
            );
          }
          handleSaveProfessionalResponse(response);
          dispatch(getClinicOwnerById(String(userId)));
        }
      } else {
        const response = await dispatch(
          createProfessional(currentProfessionalData!),
        );
        handleSaveProfessionalResponse(response);
      }
    }
  };

  const closeCreationPopup = () => {
    dispatch(setProfileChanges(null));
    dispatch(professionalProfileActions.setUpdatedProfessionalProfile());
    dispatch(setCreatedProfessionalProfile(false));
    navigate(
      isPrivateDoctor ? PathNames.clinicOwnerSchedule : PathNames.professionals,
    );
  };

  const handleCloseWarningsModal = () => {
    setSaveProfessionalWarnings([]);
    closeCreationPopup();
  };

  const getCurrentForm = () => {
    switch (activeForm) {
      case 0:
        return (
          <ProfessionalAddForm
            onNextBtnClick={handleNextBtn}
            isClinicOwnerProfile={isClinicOwnerProfile}
          />
        );
      case 1:
        return (
          <GeneralProfileInfo
            onNextBtnClick={handleNextGeneralBtn}
            onBackBtnClick={handleBackBtn}
          />
        );
      case addedClinics.length
        ? addedClinics.length + 2
        : addedClinics.length + 3:
        return (
          <ProfessionalSubscriptions
            onNextBtnClick={handleNextBtn}
            onBackBtnClick={handleBackBtn}
            isClinicOwnerProfile={isClinicOwnerProfile}
            onSave={onSave}
          />
        );
      default:
        return (
          <ClinicProfile
            addClinic={handleAddClinic}
            arrayIndex={activeForm > 1 ? activeForm - 1 : 0}
            activeForm={activeForm}
            onNextBtnClick={handleNextBtn}
            onBackBtnClick={handleBackBtn}
            onRemoveClinic={handleRemoveClinic}
            isClinicOwnerProfile={isClinicOwnerProfile}
            onSave={onSave}
          />
        );
    }
  };

  return (
    <Wrapper>
      {(isLoading || isProfessionalUpdateLoading) && <Loader />}
      <StyledSection>
        <StepSectionWrapper>
          <StepSection>
            <StepItem>
              {activeForm < 1 ? (
                <Step isActive={activeForm === 0}>1</Step>
              ) : (
                <Step isActive={true}>
                  <CheckIcon />
                </Step>
              )}
              <StepText isActive={activeForm === 0}>
                {t('users.profile_info')}
              </StepText>
            </StepItem>
            <StepItem>
              {activeForm < 2 ? (
                <Step isActive={activeForm === 1}>2</Step>
              ) : (
                <Step isActive={true}>
                  <CheckIcon />
                </Step>
              )}
              <StepText isActive={activeForm === 1}>
                {t('description')}
              </StepText>
            </StepItem>
            {activeForm <= 1 && addedClinics.length === 0 && (
              <StepItem>
                <Step isActive={false}>{3}</Step>
                <StepText isActive={false}>
                  {isPrivateDoctor
                    ? t('professional_profile.my_practice')
                    : t('clinics')}
                </StepText>
              </StepItem>
            )}
            {(activeForm >= 2 || addedClinics.length > 0) &&
              addedClinics.map((clinic, index) => {
                return (
                  <StepItem key={clinic}>
                    <Step isActive={activeForm > index + 2}>
                      {activeForm > index + 2 ? <CheckIcon /> : 3 + index}
                    </Step>
                    <StepText isActive={activeForm === index + 2}>
                      {isPrivateDoctor
                        ? t('professional_profile.my_practice')
                        : clinic}
                    </StepText>
                  </StepItem>
                );
              })}
            {showSubscriptionStep && (
              <StepItem>
                <Step isActive={false}>{subscriptionStep}</Step>
                <StepText isActive={false}>
                  {t('professional_profile.subscriptions.subscription_title')}
                </StepText>
              </StepItem>
            )}
          </StepSection>
        </StepSectionWrapper>
        {getCurrentForm()}
      </StyledSection>
      <SuccessModal
        open={isCreatedProfessionalProfile || isUpdatedProfessionalProfile}
        profileName={`${profileChanges?.firstName || ''} ${
          profileChanges?.lastName || ''
        }`}
        profileEmail={profileChanges?.email || ''}
        onClose={closeCreationPopup}
        isClinicOwnerProfile={isClinicOwnerProfile}
      />
      <ProfessionalUpdateWarningsModal
        isClinicOwnerProfile={isClinicOwnerProfile}
        isOpen={saveProfessionalWarnings.length > 0}
        warnings={saveProfessionalWarnings}
        onClose={handleCloseWarningsModal}
        onButtonClick={handleCloseWarningsModal}
      />
    </Wrapper>
  );
};

export default LayoutComponent;
