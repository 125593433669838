import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ReactComponent as ChevronIcon } from 'applicaiton/assets/chevron-right.svg';
import { ReactComponent as RestartIcon } from 'applicaiton/assets/restart_alt.svg';
import {
  CalendarView,
  ViewBy,
  AgendaView,
} from 'applicaiton/constants/scheduler';
import { getCalculatedFormattedDate } from 'features/feature-scheduler/helpers/getCalculatedFormattedDate';
import {
  refreshAgenda,
  setAgendaView,
  setCalendarView,
  setClinicId,
  setIsAddNewEvent,
  setSelectedDate,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { SecondaryButton } from 'common/components';

import { Wrapper, ButtonGroup, ButtonStyled, DaysPicker } from './styles';
import PatientSearch from '../PatientSearch';
import { searchClinicsInChain } from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { searchEntityType } from 'features/feature-scheduler/constants/searchEntityType';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getAdminById } from 'applicaiton/store/reducers/Admins/ActionCreators';
import {
  setCurrentAppointment,
  setIsHighlightedAppointment,
} from 'applicaiton/store/reducers/Appointments/PatientAppoinmentsSlice';
import { setClearPatientSearch } from 'applicaiton/store/reducers/Patients/PatientsSlice';
import { userRoleExactMatch } from 'common/helpers/userRoleExactMatch';

require('dayjs/locale/pt');
require('dayjs/locale/fr');

const NavigateBar: FC = () => {
  const userRole = getUserRole();
  const userId = getUserId();
  const isUserRoleProfessional = userRole === UserRoles.professional;
  const isUserRoleAdmin = userRoleExactMatch(UserRoles.admin);
  const isUserRoleClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { viewBy, byAvailability, byProfessionals } = useAppSelector(
    (state) => state.scheduler,
  );
  const [activeView, setActiveView] = useState(2);

  const { clinicsByOwnerId } = useAppSelector((state) => state.clinics);
  const { currentUser } = useAppSelector((state) => state.admins);
  const { isHighlighted } = useAppSelector(
    (state) => state.patientAppointments,
  );

  const isViewByProfessionals = viewBy === ViewBy.byProfessionals;
  const currentView = isViewByProfessionals ? byProfessionals : byAvailability;
  const isWeeklyView = byProfessionals.calendarView === CalendarView.weekly;
  const selectedDate = isViewByProfessionals
    ? byProfessionals.selectedDate
    : byAvailability.selectedDate;
  const clinicId = isViewByProfessionals
    ? byProfessionals.clinicId
    : byAvailability.clinicId;
  const daysByView = isWeeklyView ? 7 : 1;

  useEffect(() => {
    if (!isUserRoleProfessional) {
      dispatch(
        searchClinicsInChain({
          entityType: searchEntityType.clinicOwner,
          entityId: isUserRoleClinicOwner ? String(userId) : '1',
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (isUserRoleAdmin && userId) {
      dispatch(getAdminById(String(userId)));
    }
  }, [isUserRoleAdmin]);

  const clearPatientSearchResult = () => {
    if (isHighlighted) {
      dispatch(setCurrentAppointment(null));
      dispatch(setIsHighlightedAppointment(false));
    }
    dispatch(setClearPatientSearch(true));
  };

  const handleChangeCalendarView = useCallback(
    (view: CalendarView) => {
      setActiveView(view === CalendarView.daily ? 1 : 2);
      dispatch(setAgendaView(AgendaView.agenda));
      dispatch(setCalendarView(view));
    },
    [byProfessionals.calendarView],
  );

  const handleChangeAgendaView = useCallback(
    (view: AgendaView) => {
      setActiveView(0);
      dispatch(setAgendaView(view));
      dispatch(setCalendarView(CalendarView.daily));
    },
    [currentView.agendaView],
  );

  const handleIncreaseDate = () => {
    const increasedDayOfWeek = dayjs(selectedDate).day() + daysByView;
    const increasedDate = getCalculatedFormattedDate(
      selectedDate,
      increasedDayOfWeek,
    );

    dispatch(setSelectedDate({ date: increasedDate, key: viewBy }));
    clearPatientSearchResult();
  };

  const handleDecreaseDate = () => {
    const decreasedDayOfWeek = dayjs(selectedDate).day() - daysByView;
    const decreasedDate = getCalculatedFormattedDate(
      selectedDate,
      decreasedDayOfWeek,
    );

    dispatch(setSelectedDate({ date: decreasedDate, key: viewBy }));
    clearPatientSearchResult();
  };

  const handleRefreshAgenda = () => {
    dispatch(setIsAddNewEvent(false));
    dispatch(refreshAgenda());
  };

  const getSelectedDays = () => {
    if (isWeeklyView) {
      const currDate = dayjs(selectedDate).locale(i18n.language); // get current date
      const dayOfWeek = currDate.day() === 0 ? 6 : currDate.day() - 1;
      const first = currDate.date() - dayOfWeek; // First day is the day of the month - the day of the week
      const last = first + 6; // last day is the first day + 6
      const currentMoth = dayjs(selectedDate).get('month');
      const lastDatePrevMonth = dayjs(
        new Date(2008, currentMoth, 0).toUTCString(),
      ).get('date');

      const firstDay = dayjs(
        new Date(
          currDate
            .month(first < 0 ? currentMoth - 1 : currentMoth)
            .set('date', first < 0 ? lastDatePrevMonth + first : first)
            .toDate(),
        ).toUTCString(),
      )
        .locale(i18n.language)
        .format('DD MMM');
      const lastDay = dayjs(
        new Date(new Date(selectedDate).setDate(last)).toUTCString(),
      )
        .locale(i18n.language)
        .format('DD MMM');
      return `${firstDay} - ${lastDay}`;
    }

    return dayjs(selectedDate)
      .locale(i18n.language)
      .format('dddd, DD/MM')
      .replace('-', ' ');
  };

  useEffect(() => {
    if (clinicsByOwnerId.length && !clinicId && !isUserRoleProfessional) {
      dispatch(setClinicId({ clinicId: clinicsByOwnerId[0].id!, key: viewBy }));
    }
    if (currentUser?.clinics?.length && !clinicId && !isUserRoleProfessional) {
      dispatch(
        setClinicId({ clinicId: currentUser?.clinics[0].id!, key: viewBy }),
      );
    }
  }, [clinicsByOwnerId, viewBy, currentUser]);

  return (
    <Wrapper>
      <div>
        <PatientSearch />
        {isViewByProfessionals ? (
          <>
            <ButtonGroup>
              <ButtonStyled
                isActive={activeView === 0}
                onClick={() => handleChangeAgendaView(AgendaView.list)}
              >
                {t('scheduler.list')}
              </ButtonStyled>
              <ButtonStyled
                isActive={activeView === 1}
                onClick={() => handleChangeCalendarView(CalendarView.daily)}
              >
                {t('scheduler.daily')}
              </ButtonStyled>
              <ButtonStyled
                isActive={activeView === 2}
                onClick={() => handleChangeCalendarView(CalendarView.weekly)}
              >
                {t('scheduler.weekly')}
              </ButtonStyled>
            </ButtonGroup>
            <DaysPicker>
              <button onClick={handleDecreaseDate}>
                <ChevronIcon />
              </button>
              <p>{getSelectedDays()}</p>
              <button onClick={handleIncreaseDate}>
                <ChevronIcon />
              </button>
            </DaysPicker>
          </>
        ) : (
          ''
        )}
      </div>
      <div>
        <SecondaryButton styleType={'color'} onClick={handleRefreshAgenda}>
          <RestartIcon />
        </SecondaryButton>
      </div>
    </Wrapper>
  );
};

export default memo(NavigateBar);
