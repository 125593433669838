import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Gender } from 'applicaiton/constants/gender';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

import { DatePicker, Input, InputPhone, RadioButton } from 'common/components';
import { usePronunciationOptions } from 'common/hooks/usePronunciationOptions';
import { getRoundedDateInUTC } from 'common/helpers/dateTimeHelper';

import { PatientFormProps } from './models';
import { Wrapper, PronunciationStyled, Row, ButtonSection } from './styles';

const PatientForm: FC<PatientFormProps> = ({
  watchPatient,
  getValuesPatient,
  setValuePatient,
  errorsPatient,
  registerPatient,
  handleSubmitPatient,
  clearErrorsPatient,
  isDirtyPatient,
  onCancelCreatePatientForm,
  hideCreateFamilyMemberButton,
  handleShowFamilyMemberTab,
}) => {
  const { t } = useTranslation();
  const pronunciation = usePronunciationOptions();

  const handleChangeDateOfBirth = (value: string) => {
    setValuePatient(
      'dateOfBirth',
      getRoundedDateInUTC(value).add(1, 'hour').toISOString(), // Add 1 hour to the date to avoid the date being set to the previous day
    );
    clearErrorsPatient('dateOfBirth');
  };

  const onCancel = () => {
    onCancelCreatePatientForm();
  };

  const handleAddFamilyMember = () => {
    handleShowFamilyMemberTab();
  };

  return (
    <Wrapper>
      <PronunciationStyled>
        <p>{t('gender') || ''}:</p>
        {pronunciation.map((item) => (
          <RadioButton
            key={item.value}
            id={item.value}
            label={item.label}
            checked={watchPatient('gender') === item.value}
            onChange={(e) => {
              const value =
                e.target.id === 'Male' ? Gender.Male : Gender.Female;

              setValuePatient('gender', value);
            }}
          />
        ))}
      </PronunciationStyled>
      <Row>
        <Input
          label={t('personal_information.first_name') || ''}
          id={'firstName'}
          type="text"
          register={registerPatient}
          errorMessage={errorsPatient?.firstName?.message}
          placeholder={t('patient.new_patient_name') || ''}
        />
        <Input
          label={t('personal_information.last_name') || ''}
          id={'lastName'}
          type="text"
          register={registerPatient}
          errorMessage={errorsPatient?.lastName?.message}
          placeholder={t('patient.new_patient_lastname') || ''}
        />
      </Row>
      <InputPhone
        id="phone"
        label={t('phone') || ''}
        register={registerPatient}
        errorMessage={errorsPatient.phone?.message}
        value={getValuesPatient('phone')}
        isValid={true}
      />
      <DatePicker
        id={'dateOfBirth'}
        label={t('patient.date_birth') || ''}
        value={String(getValuesPatient('dateOfBirth'))}
        onChange={handleChangeDateOfBirth}
        maxDate={Date.now()}
        minDate={0}
        errorMessage={errorsPatient?.dateOfBirth?.message}
      />
      <Input
        id="email"
        label={t('email_optional') || ''}
        type="email"
        placeholder={t('patient.new_patient_email') || ''}
        register={registerPatient}
      />
      <Input
        id="fin"
        label={t('patient.fin') || ''}
        type="text"
        placeholder={t('patient.fin_placeholder') || ''}
        register={registerPatient}
        errorMessage={errorsPatient?.fin?.message}
      />
      <Input
        id="ssn"
        label={t('patient.ssn') || ''}
        type="email"
        placeholder={t('patient.ssn_placeholder') || ''}
        register={registerPatient}
        errorMessage={errorsPatient?.ssn?.message}
      />
      <ButtonSection>
        <button type="button" onClick={onCancel}>
          <CloseIcon />
          {t('patient.cancel_create_patient')}
        </button>

        {!hideCreateFamilyMemberButton && (
          <button
            type="button"
            onClick={handleSubmitPatient(handleAddFamilyMember)}
          >
            <PlusIcon /> {t('patient.create_family_member')}
          </button>
        )}
      </ButtonSection>
    </Wrapper>
  );
};

export default PatientForm;
