import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from 'applicaiton/assets/arrow-left.svg';
import { HeaderStyled } from './styles';
import { resetProfileChanges } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { useAppDispatch } from 'common/hooks/redux';
import { PathNames } from 'applicaiton/routes';

const Header: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleReset = () => {
    dispatch(resetProfileChanges());
  };

  return (
    <HeaderStyled>
      <Link onClick={handleReset} to={PathNames.myUsers}>
        <ArrowLeftIcon />
        {t('users.back_to_users')}
      </Link>
    </HeaderStyled>
  );
};

export default Header;
