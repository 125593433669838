import React, { FC } from 'react';
import { LayoutComponentProps } from './modules';
import { SchedulerSection, Wrapper } from './styles';
import NavigateBar from '../NavigateBar';
import FilterByDoctor from '../FilterByDoctor';

const LayoutComponent: FC<LayoutComponentProps> = ({ children }) => {
  return (
    <Wrapper>
      <FilterByDoctor />
      <SchedulerSection>
        <NavigateBar />
        {children}
      </SchedulerSection>
    </Wrapper>
  );
};

export default LayoutComponent;
