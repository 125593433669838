import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isToday from 'dayjs/plugin/isToday';
import dayjs from 'dayjs';

import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as EyeIcon } from 'applicaiton/assets/eye-open.svg';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { AppointmentNotificationsTypes } from 'common/types/notifications';

import { NotificationItemProps } from './models';
import { NameStyled, StyledTr, ActionsStyled } from './styles';
import { useAppDispatch } from 'common/hooks/redux';
import { updateNotification } from 'applicaiton/store/reducers/NotificationsInbox/ActionCreators';
import { setIsRead } from 'applicaiton/store/reducers/NotificationsInbox/NotificationsInboxSlice';
import { setSlotsDetails } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';

dayjs.extend(isToday);

const NotificationItem: FC<NotificationItemProps> = ({ notification }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locale = getLanguageFromLocalStorage();
  const userRole = getUserRole();
  const isProfessional = userRole === UserRoles.professional;

  const redirectLink = `${
    isProfessional ? PathNames.schedule : PathNames.clinicOwnerSchedule
  }?appointment=${notification.appointmentNotification?.appointmentId}`;

  const appointmentStartDate = useMemo(() => {
    const date = dayjs(notification.appointmentNotification?.appointmentDate);
    return `${date.locale(locale).format('DD MMMM, YYYY')} ${t('at')} ${dayjs(
      date,
    ).format('hh:mm')}`;
  }, [t, notification]);

  const notificationTitle = useMemo(() => {
    const notificationType =
      (notification.appointmentNotification?.type as string) ||
      notification.type;

    switch (notificationType) {
      case AppointmentNotificationsTypes.APPOINTMENT_CREATED:
        return t('my_notifications.new_appointment_booked');
      case AppointmentNotificationsTypes.APPOINTMENT_RESCHEDULED:
        return t('my_notifications.reschedule_notice');
      case AppointmentNotificationsTypes.APPOINTMENT_CANCELLED:
        return t('my_notifications.cancellation_notice');
      case AppointmentNotificationsTypes.APPOINTMENT_REQUEST_NOTIFICATION:
        return t('my_notifications.appointment_request');
      default:
        return false;
    }
  }, [t, notification.appointmentNotification?.type]);

  const dateCreated = useMemo(() => {
    const date = dayjs(notification.createdAt);

    return `${date.locale(locale).format('DD MMMM, YYYY')} ${t(
      'at',
    )} ${date.format('hh:mm')}`;
  }, [t, notification.createdAt]);

  const notificationAuthor = useMemo(() => {
    const appointmentRequestPatientName = [
      notification.appointmentRequest?.patient.firstName,
      notification.appointmentRequest?.patient.lastName,
    ]
      .filter((item) => !!item)
      .join(' ');

    const patientName =
      notification.appointmentNotification?.patientName ||
      appointmentRequestPatientName;
    const notificationType =
      (notification.appointmentNotification?.type as string) ||
      notification.type;

    switch (notificationType) {
      case AppointmentNotificationsTypes.APPOINTMENT_CREATED:
        return `${patientName} ${t(
          'my_notifications.booked_appointment_on',
        )} ${appointmentStartDate}`;
      case AppointmentNotificationsTypes.APPOINTMENT_RESCHEDULED:
        return `${patientName} ${t(
          'my_notifications.rescheduled_appointment_to',
        )} ${appointmentStartDate}`;
      case AppointmentNotificationsTypes.APPOINTMENT_CANCELLED:
        return `${patientName} ${t(
          'my_notifications.cancelled_appointment_on',
        )} ${appointmentStartDate}`;
      case AppointmentNotificationsTypes.APPOINTMENT_REQUEST_NOTIFICATION:
        return `${t(
          'my_notifications.appointment_request_received',
        )} ${patientName}`;
      default:
        return false;
    }
  }, [t, notification]);

  const showPreviewButton = useMemo(() => {
    const appointmentType = notification.appointmentNotification
      ?.type as string;
    const isAppointmentDeleted =
      !!notification.appointmentNotification?.appointmentDeletedAt;
    return (
      !isAppointmentDeleted &&
      (appointmentType === AppointmentNotificationsTypes.APPOINTMENT_CREATED ||
        appointmentType ===
          AppointmentNotificationsTypes.APPOINTMENT_RESCHEDULED)
    );
  }, [notification]);

  const onReadMessage = async () => {
    const response = await dispatch(
      updateNotification({ id: notification.id, isRead: true }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(setIsRead(notification.id));
    }
  };

  const handlePreviewAppointment = () => {
    dispatch(
      setSlotsDetails({
        appointmentId: String(
          notification.appointmentNotification?.appointmentId,
        ),
        mode: ReserveSlotsMode.PreviewAppointment,
      }),
    );
  };

  return (
    <StyledTr isUnreadMessage={!notification.isRead}>
      <td>{dateCreated}</td>
      <td>
        <NameStyled isUnreadMessage={true}>
          <h2>{notificationTitle}</h2>
          <p>{notificationAuthor}</p>
        </NameStyled>
      </td>
      <td>
        <ActionsStyled>
          {!notification.isRead && (
            <button onClick={onReadMessage}>
              {t('my_notifications.mark_as_read')}
            </button>
          )}
          {showPreviewButton && (
            <Link
              to={redirectLink}
              onClick={handlePreviewAppointment}
              target="_blank"
            >
              <EyeIcon />
            </Link>
          )}
        </ActionsStyled>
      </td>
    </StyledTr>
  );
};
export default NotificationItem;
