import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CpSubscriptionCreationDto,
  ProfessionalCreationDto,
  ProfessionalResponseDto,
} from '@docbay/schemas';
import { CreateProfessionalProfileState } from './models';
import {
  createClinicOwnerProfessional,
  createProfessional,
} from './ActionCreators';

const initialState: CreateProfessionalProfileState = {
  profileChanges: null,
  isLoading: false,
  error: '',
  isClinicOwnerProfile: false,
  existingProfessional: null,
  isExistDeletedDoctor: null,
  showClinicListModal: false,
  subscriptions: [],
  isCreatedProfessionalProfile: false,
};

const createProfessionalProfileSlice = createSlice({
  name: 'createProfessionalProfile',
  initialState,
  reducers: {
    setProfileChanges: (
      state,
      actions: PayloadAction<ProfessionalCreationDto | null>,
    ) => {
      state.profileChanges = actions.payload!;
    },
    resetProfileChanges: (state) => {
      state.profileChanges = null;
    },
    setIsClinicOwnerProfile: (state, actions: PayloadAction<boolean>) => {
      state.isClinicOwnerProfile = actions.payload;
    },
    selectExistingProfessional: (
      state,
      action: PayloadAction<ProfessionalResponseDto | null>,
    ) => {
      state.existingProfessional = action.payload;
    },
    setProfileSubscriptions: (
      state,
      actions: PayloadAction<CpSubscriptionCreationDto[]>,
    ) => {
      state.subscriptions = actions.payload!;
    },
    setIsExistDeletedDoctor: (state, actions: PayloadAction<string | null>) => {
      state.isExistDeletedDoctor = actions.payload!;
    },
    setShowClinicListModal: (state, actions: PayloadAction<boolean>) => {
      state.showClinicListModal = actions.payload!;
    },
    setCreatedProfessionalProfile: (state, action: PayloadAction<boolean>) => {
      state.isCreatedProfessionalProfile = action.payload;
    },
  },
  extraReducers: (builder) => {
    //create clinic owner professional profile
    builder.addCase(createClinicOwnerProfessional.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createClinicOwnerProfessional.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createClinicOwnerProfessional.fulfilled, (state) => {
      state.isCreatedProfessionalProfile = true;
      state.isLoading = false;
    });
    //create professional
    builder.addCase(createProfessional.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProfessional.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createProfessional.fulfilled, (state) => {
      state.isCreatedProfessionalProfile = true;
      state.isLoading = false;
    });
  },
});

export const {
  setProfileChanges,
  resetProfileChanges,
  setIsClinicOwnerProfile,
  selectExistingProfessional,
  setProfileSubscriptions,
  setIsExistDeletedDoctor,
  setShowClinicListModal,
  setCreatedProfessionalProfile,
} = createProfessionalProfileSlice.actions;

export default createProfessionalProfileSlice.reducer;
