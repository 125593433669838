import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from 'applicaiton/assets/arrow-left.svg';
import { PathNames } from 'applicaiton/routes';
import { HeaderStyled } from './styles';
import { resetProfileChanges } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { useAppDispatch } from 'common/hooks/redux';
import { HeaderProps } from './modules';
import { setActiveForm } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import { ProfileForm } from 'applicaiton/constants/clincOwnerProfile';

const Header: FC<HeaderProps> = ({ isClinicOwnerProfile }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleReset = () => {
    dispatch(resetProfileChanges());
  };

  const handleChangeActiveFrom = () => {
    dispatch(setActiveForm(ProfileForm.previewProfile));
  };

  return (
    <HeaderStyled>
      {isClinicOwnerProfile ? (
        <button onClick={handleChangeActiveFrom}>
          <ArrowLeftIcon />
          {t('professionalConfiguration.back_to_docbay_profile')}
        </button>
      ) : (
        <Link onClick={handleReset} to={PathNames.professionals}>
          <ArrowLeftIcon />
          {t('professionalConfiguration.back_to_all_professionals')}
        </Link>
      )}
    </HeaderStyled>
  );
};

export default Header;
