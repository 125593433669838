import styled from 'styled-components';

export const TopicsOfChatsStyled = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px 32px 20px 32px;
  background: ${({ theme }) => theme.color.background};
  overflow-y: auto;
  height: 100%;
`;

export const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 28px;
    line-height: 22px;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }
  > button {
    width: fit-content;
    padding: 10px 24px;
    > svg {
      path {
        fill: ${({ theme }) => theme.color.grey900};
      }
    }
  }
`;
