import React, { useEffect } from 'react';
import { useChatsClient } from './hooks/useChatsClient';
import { useChats } from './hooks/useChats';
import { useDynamicChats } from './hooks/useDynamicChats';
import { useAppSelector } from '../../common/hooks/redux';
import { MainLayoutWrapper, Wrapper } from './styles';
import { Loader } from '../../common/components';
import { ActiveChat, ChatsSideBar } from './components';
import { ActiveMassMessageChat } from './components/ActiveMassMessageChat';

export const Chats = () => {
  const { currentClient } = useChatsClient();
  const { fetchChats, isLoading, chatsAll } = useChats();
  const { initDynamicChat, initDynamicChatListeners } = useDynamicChats();
  const { massMessagesPatientsIds } = useAppSelector((state) => state.chats);
  const { isLoading: isLoadingProfessional } = useAppSelector(
    (state) => state.professionals,
  );

  useEffect(() => {
    if (!!currentClient && chatsAll?.length > 0) {
      initDynamicChat(chatsAll);
    }
  }, [chatsAll]);

  useEffect(() => {
    if (currentClient) {
      fetchChats().then(() => initDynamicChatListeners());
    }
  }, [currentClient]);

  return (
    <Wrapper>
      <MainLayoutWrapper>
        <ChatsSideBar />
        {massMessagesPatientsIds ? <ActiveMassMessageChat /> : <ActiveChat />}
      </MainLayoutWrapper>

      {(isLoading || isLoadingProfessional) && <Loader />}
    </Wrapper>
  );
};
