import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/unsuccess-icon.svg';
import { ErrorProps } from './modules';

const ErrorModal: FC<ErrorProps> = ({ onClose, text, isOpen }) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <WarningIcon />
          <p>{text}</p>

          <SecondaryButton onClick={onClose}>
            {t('clinic_owner.error_email.back_to_profile')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default ErrorModal;
