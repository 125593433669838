import React, { FC, memo, useRef, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { StyledInput } from './styled';
import 'react-phone-input-2/lib/material.css';

interface InputPhoneProps {
  id: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  hint?: string;
  register: UseFormRegister<any>;
  value?: number | string;
  isValid: boolean;
  allowChangeDialCode?: boolean;
  hideCountryCode?: boolean;
  onChange?: (e: string, dialCode: string) => void;
}

const InputPhone: FC<InputPhoneProps> = ({
  id,
  placeholder,
  label,
  disabled,
  errorMessage,
  hint,
  register,
  value,
  isValid,
  allowChangeDialCode = false,
  hideCountryCode = false,
  onChange,
}) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <StyledInput
      isActive={isActive}
      isError={!!errorMessage || (!!value && !isValid)}
      disabled={disabled}
    >
      <label>{label}</label>
      <section
        ref={ref}
        onMouseDown={() => setIsActive(true)}
        className="phone-input-section"
      >
        <PhoneInput
          placeholder={placeholder}
          specialLabel={''}
          country={'pt'}
          {...register(id)}
          value={String(value)}
          onChange={(e, data) => {
            if (hideCountryCode) {
              const dataCode = data as CountryData;

              onChange && onChange(e, dataCode?.dialCode || '');
            } else {
              register &&
                register(id).onChange({
                  target: { value: e, name: id },
                  type: 'change',
                });
            }
          }}
          enableSearch={true}
          searchPlaceholder={'Search by countries'}
          countryCodeEditable={!!allowChangeDialCode}
          disabled={disabled}
        />
      </section>
      <span>{errorMessage ? errorMessage : hint}</span>
    </StyledInput>
  );
};

export default memo(InputPhone);
