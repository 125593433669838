import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  display: flex;
  padding: 32px 32px 32px 32px;
  flex-direction: column;
  background: #f0fbff;

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 39.2px;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }
`;

export const SettingsStyled = styled.div`
  display: grid;
  grid-template-rows: max-content;
  gap: 32px;
  padding: 32px;
  margin-top: 22px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  border-top: none;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grey200};
`;
