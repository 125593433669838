import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { userRoleExactMatch } from '../helpers/userRoleExactMatch';

export const useCheckUserRole = () => {
  const userRole = getUserRole();
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isProfessional = userRole?.includes(UserRoles.professional);
  const isAdmin = userRoleExactMatch(UserRoles.admin);
  const isSecretary = userRole?.includes(UserRoles.secretary);

  const correctUserRole =
    !userRole ||
    (userRole?.length &&
      (isAdmin || isSecretary || isClinicOwner || isProfessional));

  if (correctUserRole) {
    return { correctUserRole: true };
  } else {
    return { correctUserRole: false };
  }
};
