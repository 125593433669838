import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import {
  createPatient,
  fetchPatients,
} from 'applicaiton/store/reducers/Patients/ActionCreators';

import { useAppDispatch } from 'common/hooks/redux';
import { Option } from 'common/components/Dropdown/models';
import { usePronunciationOptions } from 'common/hooks/usePronunciationOptions';
import { Option as ClinicOption } from 'common/components/DropdownClinics/models';
import {
  DatePicker,
  DropdownClinics,
  Input,
  InputPhone,
  PrimaryButton,
  RadioButton,
  SecondaryButton,
} from 'common/components';

import { usePatientForm } from 'features/feature-my-patients/hooks/usePatientForm';

import { AddPatientModalProps, FormData } from './models';
import {
  RadioButtonSection,
  StyledModal,
  HeaderStyled,
  ButtonSection,
  FormStyled,
  RowContainer,
  ClinicSection,
} from './styles';

const AddPatientModal: FC<AddPatientModalProps> = ({
  isOpen,
  onClose,
  handleOpenSuccessModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const pronunciations = usePronunciationOptions();
  const patientSchema = usePatientForm();
  const [clinics, setClinics] = useState<ClinicOption[]>([]);

  const {
    setValue,
    watch,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(patientSchema),
    defaultValues: {
      pronunciation: 'Male',
      dateOfBirth: dayjs().toISOString(),
    },
  });

  const handleClose = () => {
    onClose();
    reset();
    setClinics([]);
  };

  const handleChangePronunciation = (value: string) => {
    setValue('pronunciation', value, {
      shouldValidate: !!errors.pronunciation,
    });
  };
  const handleChangeDateOfBirth = (value: string) => {
    setValue('dateOfBirth', value, {
      shouldValidate: !!errors.dateOfBirth,
    });
  };

  const handleSelectClinic = (clinics: ClinicOption[]) => {
    setClinics(clinics);
    setValue(
      'clinicName',
      clinics.map((item) => String(item.value)),
      { shouldValidate: !!errors.clinicName },
    );
  };

  const onSubmit = async (data: FormData) => {
    const {
      dateOfBirth,
      email,
      phone,
      clinicName,
      socialSecurityNumber,
      nif,
      ...restData
    } = data;
    const response = await dispatch(
      createPatient({
        ...restData,
        ...(email ? { email } : {}),
        clinicIds: clinicName,
        phone: phone.startsWith('+') ? phone : '+'.concat(phone),
        dateOfBirth: dayjs(watch('dateOfBirth')).toISOString(),
        ...(socialSecurityNumber ? { socialSecurityNumber } : {}),
        ...(nif ? { nif } : {}),
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      handleClose();
      handleOpenSuccessModal();
      dispatch(
        fetchPatients({
          page: 1,
          limit: 10,
        }),
      );
    }
  };

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <HeaderStyled>
          <h1>{t('my_patients.add_new_patient')}</h1>
          <button type="button" onClick={handleClose}>
            <CloseIcon />
          </button>
        </HeaderStyled>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <div>
            <RadioButtonSection>
              <p>{t('gender')}</p>
              <div>
                {pronunciations.map((item) => (
                  <RadioButton
                    key={item.value}
                    id={item.value}
                    label={item.label}
                    checked={watch('pronunciation') === item.value}
                    onChange={(e) => handleChangePronunciation(e.target.id)}
                  />
                ))}
              </div>
            </RadioButtonSection>
            <RowContainer>
              <Input
                id={'firstName'}
                type="text"
                label={t('personal_information.first_name') || ''}
                placeholder={
                  t('personal_information.first_name_placeholder') || ''
                }
                register={register}
                errorMessage={errors.firstName?.message}
              />
              <Input
                id={'lastName'}
                type="text"
                label={t('personal_information.last_name') || ''}
                placeholder={
                  t('personal_information.last_name_placeholder') || ''
                }
                register={register}
                errorMessage={errors.lastName?.message}
              />
            </RowContainer>
            <RowContainer>
              <InputPhone
                id="phone"
                label={t('phone') || ''}
                register={register}
                errorMessage={errors.phone?.message}
                value={watch('phone')}
                isValid={true}
              />
              <DatePicker
                id={'dateOfBirth'}
                label={t('patient.date_birth') || ''}
                value={watch('dateOfBirth')}
                onChange={handleChangeDateOfBirth}
                maxDate={Date.now()}
                minDate={0}
                format={'DD/MM/YYYY'}
                errorMessage={errors?.dateOfBirth?.message}
              />
            </RowContainer>
            <Input
              id="email"
              label={t('email_optional') || ''}
              type="email"
              placeholder={t('patient.new_patient_email') || ''}
              register={register}
              errorMessage={errors?.email?.message}
            />
            <Input
              id="nif"
              label={t('my_patients.fiscal_identification_number') || ''}
              type="text"
              register={register}
              errorMessage={errors?.nif?.message}
            />
            <Input
              id="socialSecurityNumber"
              label={t('my_patients.social_security_number') || ''}
              type="text"
              register={register}
              errorMessage={errors?.socialSecurityNumber?.message}
            />
            <ClinicSection isError={!!errors?.clinicName}>
              <p>{t('my_patients.clinic_name')}</p>
              <DropdownClinics
                value={clinics as Option[]}
                onChange={(values) => {
                  Array.isArray(values) && handleSelectClinic(values);
                }}
                isMulti={true}
                isError={!!errors?.clinicName}
                errorMessage={errors?.clinicName && t('errors.required')!}
              />
            </ClinicSection>
          </div>
          <ButtonSection>
            <SecondaryButton onClick={handleClose}>
              {t('close')}
            </SecondaryButton>
            <PrimaryButton styleType={'default'}>{t('continue')}</PrimaryButton>
          </ButtonSection>
        </FormStyled>
      </StyledModal>
    </Modal>
  );
};

export default AddPatientModal;
