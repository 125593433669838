import React, { FC, useEffect, useMemo } from 'react';
import {
  EditContent,
  StyledDivider,
  ButtonsWrapper,
  InputSection,
} from './styles';
import { Input, InputPhone, PrimaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import {
  resetProfileChanges,
  setIsExistDeletedDoctor,
  setProfileChanges,
  setShowClinicListModal,
} from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useProfessionalForm } from '../../hooks/useProfessionalForm';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { getProfilesFromSessionStorage } from 'applicaiton/sessionStorage/auth';
import { ProfessionalAddFormProps } from './types';
import { checkUsersEmailExisting } from 'applicaiton/store/reducers/Users/ActionCreators';
import Email from '../Email';
import { UserResponseFindByEmailDto } from '@docbay/schemas/dist/user/dto/user-response-find-by-email.dto';
import { isValidPhoneNumber } from 'libphonenumber-js';
import ClinicListModal from '../ClinicListModal';

const ProfessionalAddForm: FC<ProfessionalAddFormProps> = ({
  onNextBtnClick,
  isClinicOwnerProfile,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const profiles = getProfilesFromSessionStorage();

  const {
    profileChanges,
    existingProfessional,
    isExistDeletedDoctor,
    showClinicListModal,
  } = useAppSelector((state) => state.createProfessionalProfile);

  const {
    register,
    setValue,
    errors,
    getValues,
    handleSubmit,
    watch,
    setError,
    trigger,
    reset,
    clearErrors,
  } = useProfessionalForm({
    emailError: t('errors.emailFormat'),
    requiredError: t('errors.required'),
    lengthMedicalNumberError: t('errors.at_least_2_characters'),
  });

  const currentProfile = useMemo(() => {
    if (profiles?.length && isClinicOwnerProfile) {
      return profiles[0];
    }

    return null;
  }, [profiles, isClinicOwnerProfile]);

  useEffect(() => {
    setValue('firstName', profileChanges?.firstName || '');
    setValue('lastName', profileChanges?.lastName || '');
    setValue('email', profileChanges?.email || currentProfile?.email || '');
    setValue('medicalNumber', profileChanges?.medicalNumber || '');
    profileChanges?.phone && setValue('phone', profileChanges?.phone || '');

    setHasUnsavedChanges(!!Object.keys(errors).length || !!profileChanges);
  }, []);

  useEffect(() => {
    if (isClinicOwnerProfile || !existingProfessional) return;

    setValue('firstName', existingProfessional?.firstName || '');
    setValue('lastName', existingProfessional?.lastName || '');
    setValue(
      'email',
      existingProfessional?.user?.email || getValues('email') || '',
    );
    setValue('medicalNumber', existingProfessional?.medicalNumber || '');
    setValue('phone', existingProfessional?.phone || '');
    dispatch(
      setProfileChanges({
        firstName: existingProfessional?.firstName,
        lastName: existingProfessional?.lastName,
        email: existingProfessional?.user?.email || '',
        medicalNumber: existingProfessional?.medicalNumber || '',
        dateOfBirth: existingProfessional?.dateOfBirth || '',
        gender: existingProfessional?.gender!,
        spokenLanguageIds:
          existingProfessional?.professionalSpokenLanguages?.map(
            (item) => item.id,
          ) || [],
        description: existingProfessional?.description,
        photos: existingProfessional?.photos,
        socialMedia: existingProfessional?.socialMedia,
        ...(existingProfessional?.phone
          ? { phone: existingProfessional?.phone }
          : {}),
        // --- Will be use in future ---
        // professionalActivityStartDate: String(
        //   existingProfessional?.professionalActivityStartDate,
        // ),
        // ---------------------------
      }),
    );
    setHasUnsavedChanges(
      !!Object.keys(errors).length || !!existingProfessional,
    );
  }, [existingProfessional]);

  useEffect(() => {
    const { firstName, lastName, email, medicalNumber, phone } = getValues();
    const isChanged =
      firstName.length ||
      lastName.length ||
      email.length ||
      medicalNumber.length ||
      phone.length ||
      !!profileChanges;

    dispatch(setHasUnsavedChanges(!!isChanged));
  }, [
    watch('firstName'),
    watch('lastName'),
    watch('email'),
    watch('medicalNumber'),
    watch('phone'),
  ]);

  const onSubmit = async () => {
    const { firstName, lastName, email, medicalNumber, phone } = getValues();

    if (!isClinicOwnerProfile && !existingProfessional) {
      const response = await dispatch(checkUsersEmailExisting(email));
      const payload = response.payload as UserResponseFindByEmailDto;

      const isDoctorAlreadyExist =
        response.meta.requestStatus === 'fulfilled' && payload?.isExist;
      if (isDoctorAlreadyExist) {
        dispatch(setIsExistDeletedDoctor(email));
        return setError('email', {
          message: t('errors.doctor_is_already_exists') || '',
        });
      } else {
        isExistDeletedDoctor && dispatch(setIsExistDeletedDoctor(null));
      }
    }
    const isAddPhone = isValidPhoneNumber(`+${String(phone)}`)
      ? { phone: phone.startsWith('+') ? phone : `+${String(phone)}` }
      : {};

    dispatch(
      setProfileChanges({
        ...profileChanges!,
        firstName,
        lastName,
        email: email as string,
        medicalNumber,
        ...isAddPhone,
      }),
    );

    onNextBtnClick();
  };

  useEffect(() => {
    return () => {
      dispatch(resetProfileChanges());
    };
  }, []);

  const invalidFields =
    !watch('email') || !!errors?.email?.message || !!existingProfessional;

  const handleCloseModal = () => {
    dispatch(setShowClinicListModal(false));
  };
  return (
    <EditContent>
      <div>
        <InputSection>
          {!isClinicOwnerProfile && (
            <Email
              errorMessage={errors?.email?.message}
              setValue={setValue}
              trigger={trigger}
              defaultValue={
                existingProfessional?.user?.email || profileChanges?.email || ''
              }
              reset={reset}
              clearErrors={clearErrors}
            />
          )}
          <InputPhone
            id="phone"
            label={t('phone') || ''}
            register={register}
            errorMessage={errors.phone?.message}
            value={watch('phone')}
            isValid={true}
            disabled={invalidFields}
            hideCountryCode
            onChange={(val, dialCode) => {
              setValue('phone', val, { shouldValidate: val !== dialCode });
              if (val === dialCode || isValidPhoneNumber(val)) {
                clearErrors('phone');
              } else {
                setError('phone', { message: t('invalid_phone_number') || '' });
              }
            }}
          />
          <Input
            label={t('personal_information.first_name') || ''}
            id={'firstName'}
            type="text"
            register={register}
            errorMessage={errors?.firstName?.message}
            placeholder={t('personal_information.first_name_placeholder') || ''}
            disabled={invalidFields}
          />
          <Input
            label={t('personal_information.last_name') || ''}
            id={'lastName'}
            type="text"
            register={register}
            errorMessage={errors?.lastName?.message}
            placeholder={t('personal_information.last_name_placeholder') || ''}
            disabled={invalidFields}
          />
          <Input
            label={t('professional_profile.medical_number') || ''}
            id={'medicalNumber'}
            type="text"
            register={register}
            errorMessage={errors?.medicalNumber?.message}
            placeholder={
              t('personal_information.medical_number_placeholder') || ''
            }
            disabled={invalidFields}
          />
        </InputSection>
      </div>
      <StyledDivider />
      <ButtonsWrapper>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!!Object.keys(errors).length}
        >
          {t('next')}
        </PrimaryButton>
      </ButtonsWrapper>
      <ClinicListModal onClose={handleCloseModal} open={showClinicListModal} />
    </EditContent>
  );
};

export default ProfessionalAddForm;
